<template>
  <div>
    <el-dialog
      custom-class="ql-dialog fit-width-content"
      title="新增学生"
      :visible.sync="addStudentDialogVisible"
      @close="dialogClose"
    >
      <el-form ref="form" :model="formData" label-width="75px">
        <el-form-item label="新增学生" prop="newStudent" size="small">
          <el-select
            v-model="formData.stuId"
            placeholder="请输入学生姓名"
            style="width: 392px"
            filterable
            @change="selectStudentChange"
            :filter-method="dataFilter"
          >
            <el-option v-for="item in studentOptions" :key="item.stuId" :label="item.stuName" :value="item.stuId">
              {{ item.stuName }} {{ item.idNumber }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="handleCancel">取消</el-button>
        <el-button type="primary" size="small" v-debounceClick="handleSave" :disabled="!formData.stuId || disable">
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Form, FormItem, Select, Option, Button } from 'element-ui';

//获取可用于新增的学生
import { selectNotInClassStudent } from '@/api/basic';

export default {
  name: 'AddStudentDialog',

  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [Button.name]: Button,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    addStudentDialogFlag: {
      type: Boolean,
    },
    classId: {
      type: String,
    },
  },

  data() {
    return {
      addStudentDialogVisible: this.addStudentDialogFlag,
      formData: {
        stuId: '',
      },
      studentOptions: [],
      disable: true,
    };
  },

  methods: {
    dialogClose() {
      this.formData.stuId = '';
      this.studentOptions = [];
      this.$emit('update:addStudentDialogFlag', false);
    },

    handleCancel() {
      this.dialogClose();
    },

    handleSave() {
      const classNames = this.studentOptions.find((item) => item.stuId === this.formData.stuId).classNames;

      // 检查该学生是否已在其他班
      if (classNames?.length) {
        const h = this.$createElement;
        this.$msgbox({
          message: h('div', null, [
            h('h3', { class: 'T3B mg-b-13' }, `该学生已在${classNames.join('、')}，确认新增该学生吗？`),
          ]),
          type: 'warning',
          customClass: 'ql-message-box_notitle',
          showClose: false,
          showCancelButton: true,
        })
          .then(() => {
            this.emitAdd();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作',
            });
          });
      } else {
        this.emitAdd();
      }
    },

    // 确定新增，触发外部方法
    emitAdd() {
      this.$emit('addStudent', {
        stuId: this.formData.stuId,
        classId: this.classId,
      });
      this.dialogClose();
    },

    //
    selectStudentChange(val) {
      let student = this.studentOptions.filter((item) => item.stuId == val)[0];
      if (student.classId == this.classId) {
        this.$message({
          message: '该学生已在班级内',
          type: 'warning',
          showClose: true,
        });
        this.disable = true;
      } else {
        this.disable = false;
      }
    },

    dataFilter(val) {
      if (val) {
        selectNotInClassStudent({
          classId: this.classId,
          stuName: val.trim(),
        }).then((res) => {
          this.studentOptions = res.result;
        });
      } else {
        this.studentOptions = [];
      }
    },
  },

  watch: {
    addStudentDialogFlag() {
      this.addStudentDialogVisible = this.addStudentDialogFlag;
    },

    classId() {
      this.studentOptions = [];
    },
  },
};
</script>
