<template>
  <el-dialog
    custom-class="ql-dialog messageDialog"
    title="信息"
    :visible.sync="messageDialogVisible"
    @close="dialogClose"
    width="450px"
  >
    <!-- 警告标识 -->
    <div class="warning">
      <i class="el-icon-warning"></i>
      <span>该班级已绑定盒子,请前往"盒子管理平台"重置盒子。</span>
    </div>
    <span slot="footer">
      <el-button size="small" @click="dialogClose">取消</el-button>
      <el-button type="primary" size="small" @click="dialogClose">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Button, Dialog } from 'element-ui';
export default {
  name: 'MessageDialog',
  data() {
    return {
      messageDialogVisible: this.messageFlag,
    };
  },
  props: {
    messageFlag: {
      type: Boolean,
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },
  methods: {
    dialogClose() {
      this.$emit('messageClose');
      this.$emit('update:messageFlag', false);
    },
  },
  watch: {
    messageFlag() {
      this.messageDialogVisible = this.messageFlag;
    },
  },
};
</script>
<style lang="scss" scoped></style>
