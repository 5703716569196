// methods
import {
  getClassList,
  getTeacherStudentList,
  getCurrentYear,
  getGradeList,
  exportClassNameStick,
  getDotProduceDownloadStatus,
} from '@/api/basic';
import { downloadFileByA } from '@/utils/common';
import { getToken } from '@/utils/cookies';
import cloneDeep from 'lodash.clonedeep';

// components
import { Button, Table, TableColumn } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import classDialog from './components/classDialog';
import studentListDialog from './components/studentListDialog';
import batchExport from '@/components/batchExport/content';
import ETable from '@/components/table/index.vue';
import EButton from '@components/button/index.vue';

//删除班级
import delClassDialog from './components/delClassDialog';
import searchInput from '@/components/searchInput';
import OperationBtns from '@components/table/components/operationBtns.vue';

export default {
  name: 'class',

  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    basicHeader,
    qlPagination,
    classDialog,
    studentListDialog,
    delClassDialog,
    searchInput,
    ETable,
    EButton,
  },

  data() {
    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      gradeList: [],
      selectedGradeId: -1,

      visible: false,
      formData: {
        // index: -1,
        // classId: null,
        // className: "",
        // gradeId: null,
        // currentYear: "",
        // teacherId: ""
      },
      isEdit: false,
      teacherOptions: [],

      // 学生名单
      student: {
        dialogFlag: false,
        studentListData: [],
        gradeId: '',
        classId: '',
        className: '',
        gradeName: '',
      },
      //删除班级
      delClassDialogFlag: false,
      message: '',
      // 批量导出绑笔名单消息提示实例
      msgInstance: null,
      classSearchKey: '',

      currentYear: {},

      selectedRows: [],
      filterOptions: {},
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.pageChange,
      },
    };
  },

  methods: {
    filterChangeHandler(filterObj) {
      // 可选
      this.filterOptions = {
        ...this.filterOptions,
        [Object.keys(filterObj)[0]]:
          Object.values(filterObj)[0].length > 0 ? Object.values(filterObj)[0].join(',') : undefined,
      };
    },

    sortChange({ order, column }) {
      if (order) {
        this.filterOptions[column.columnKey] = order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.filterOptions[column.columnKey] = '';
      }

      this.pagination.page = 1;
      this.getTableData();
    },

    handleSelectAll() {
      this.getAllTableData();
    },

    pageChange() {
      this.getTableData();
    },

    async getCurrentYear() {
      const res = await getCurrentYear();

      if (res.status === 0) {
        this.currentYear = res.result;
      }
    },

    async getTableData() {
      const res = await getClassList({
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.page,

        ...this.filterOptions,
        gradeIds: this.filterOptions.gradeIds?.split(','),
      });

      if (res.status === 0) {
        this.listData.list = res.result.data;
        this.pagination.total = +res.result.recordCount;
      }
    },

    async getAllTableData() {
      const res = await getClassList({
        pageSize: this.pagination.total,
        pageIndex: 1,

        ...this.filterOptions,
        gradeIds: this.filterOptions.gradeIds?.split(','),
      });

      if (res.status === 0) {
        this.selectedRows = res.result.data;
      }
    },

    // 编辑
    async handleEdit(row) {
      this.isEdit = true;
      this.formData = cloneDeep(row);
      this.visible = true;
    },

    handleSuccess() {
      this.getTableData();
      this.visible = false;
    },

    //删除班级
    async handleDel(val) {
      const res = await getTeacherStudentList({
        classId: val.classId,
      });

      if (res.status === 0) {
        this.student.classId = val.classId;
        let mes = `你确定删除”${val.className}“吗？`;
        this.message = mes;
        this.delClassDialogFlag = true;
      }
    },

    //查看班级名单
    async checkClassStudentList(val) {
      this.student.dialogFlag = true;
      this.student.className = val.className;
      this.student.gradeName = val.gradeName;
      this.student.classId = val.classId;
      this.student.gradeId = val.gradeId;
    },

    // 批量录入
    handleImport() {
      this.$batchEntry({
        type: 'classUser',
        downloadMethod: 'GET',
        callback: () => {
          this.getTableData();
        },
      });
    },

    // 批量导出班级名单
    handleExport() {
      let empty = this.selectedRows
        .filter((item) => typeof item.nums !== 'number' || item.nums === 0)
        .map((item) => `${item.gradeName}${item.className}`);

      if (empty.length) {
        this.$message.warning(`${empty.join(',')}班级人数为0，不支持导出`);
        return;
      }

      this.$batchExport({
        type: 'classUser',
        data: {
          classIdList: this.selectedRows.map((item) => item.classId).join(),
        },
        downloadMethod: 'GET',
      });
    },

    // 批量导出绑笔名单
    handleExportBindPenUser(classId) {
      if (this.msgInstance) {
        this.$message.warning('正在执行导出操作，请稍后重试');
        return;
      }

      if (!classId && !this.selectedRows.length) {
        this.$message.warning('请选择要导出绑笔名单的班级');
        return;
      }

      let empty = this.selectedRows
        .filter((item) => typeof item.nums !== 'number' || item.nums === 0)
        .map((item) => `${item.gradeName}${item.className}`);

      if (empty.length) {
        this.$message.warning(`${empty.join(',')}班级人数为0，不支持导出`);
        return;
      }

      const h = this.$createElement;
      this.msgInstance = this.$message({
        message: h(batchExport, null),
        customClass: 'batchExport-message',
        duration: 0,
        showClose: false,
        onClose: () => {},
      });

      let url = `${
        process.env.NODE_ENV === 'development' ? window.$ctx : window.location.origin
      }/serv-manager/basicSchClass/exportUserBinding?isAppLogon=true&token=${getToken()}&schoolId=${
        this.userInfo.schoolId
      }`;

      // 有年级班级说明是导出单个班级的绑笔名单否则导出的是表格选中班级的绑笔名单
      if (classId) {
        url += `&classIds=${classId}`;
      } else {
        const classIds = this.selectedRows.map((item) => item.classId);
        for (let _id of classIds) {
          url += `&classIds=${_id}`;
        }
      }

      setTimeout(() => {
        downloadFileByA(url, '', {}, () => {
          this.clearMsgInstance();
        });
      }, 1000);
    },

    // 批量导出学生姓名贴
    handleExportUserName(classId) {
      if (this.msgInstance) {
        this.$message.warning('正在执行导出操作，请稍后重试');
        return;
      }

      if (!classId && !this.selectedRows.length) {
        this.$message.warning('请选择要导出学生姓名贴的班级');
        return;
      }

      let empty = this.selectedRows
        .filter((item) => typeof item.nums !== 'number' || item.nums === 0)
        .map((item) => `${item.gradeName}${item.className}`);

      if (empty.length) {
        this.$message.warning(`${empty.join(',')}班级人数为0，不支持导出`);
        return;
      }

      const h = this.$createElement;
      this.msgInstance = this.$message({
        message: h(batchExport, null),
        customClass: 'batchExport-message',
        duration: 0,
        showClose: false,
        onClose: () => {},
      });

      // 有年级班级说明是导出单个班级的学生姓名贴
      exportClassNameStick({
        schoolId: this.userInfo.schoolId,
        classIds: classId ? classId : this.selectedRows.map((item) => item.classId),
      })
        .then((res) => {
          if (res.status !== 0 || !res.result.homeworkId) {
            this.clearMsgInstance();
            return;
          }
          this.getStatus(res.result.homeworkId);
        })
        .catch(() => {
          this.clearMsgInstance();
        });
    },

    async getStatus(homeworkId) {
      const res = await getDotProduceDownloadStatus({ homeworkId });
      if (res.status === 0 && res.result) {
        switch (res.result.status) {
          case 1:
          case 2:
            setTimeout(() => {
              this.getStatus(homeworkId);
            }, 3000);
            break;
          case 3:
            setTimeout(() => {
              downloadFileByA(res.result.downloadAddress, '', {}, () => {
                this.clearMsgInstance();
              });
            }, 1000);
            break;
          case 4:
            this.$message.error(res.result.msg || '导出失败，请重新尝试');
            this.clearMsgInstance();
            break;
          default:
            this.clearMsgInstance();
            break;
        }
      } else {
        this.$message.error('导出失败，请重新尝试');
        this.clearMsgInstance();
      }
    },

    clearMsgInstance() {
      this.msgInstance.close();
      this.msgInstance = null;
    },

    delClassConfirm() {
      this.$message({
        message: '删除成功',
        type: 'success',
      });

      this.getTableData();
    },

    async getGradeList() {
      const res = await getGradeList({
        status: 0,
      });

      if (res.status === 0) {
        this.gradeList = res.result.map((item) => ({ text: item.gradeName, value: item.gradeId }));
      }
    },
  },

  mounted() {
    this.getCurrentYear();
    this.getGradeList();
    this.getTableData();
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    studentList() {
      return this.student.studentListData;
    },

    notAllowOperate() {
      // return this.userInfo.thirdpartySchoolProjectCode !== '';
      return false;
    },

    columns() {
      return [
        {
          label: '班级名称',
          prop: 'className',
          formatter: (row) => (row.className ? `${row.gradeName}${row.className}` : '-'),
        },
        {
          label: '所属年级',
          prop: 'gradeName',
          columnKey: 'gradeIds',
          filters: this.gradeList,
        },
        {
          label: '班级人数',
          prop: 'nums',
          columnKey: 'numsOrder',
          sortable: 'custom',
          formatter: (row) => row.nums || 0,
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '班级名单',
                disabled: () => this.notAllowOperate,
                handle: this.checkClassStudentList,
              },
              {
                text: '编辑',
                disabled: () => this.notAllowOperate,
                handle: this.handleEdit,
              },
              {
                text: '删除',
                disabled: () => this.notAllowOperate,
                handle: this.handleDel,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
  },

  watch: {
    filterOptions: {
      handler() {
        this.pagination.page = 1;
        this.getTableData();
      },
      deep: true,
    },

    'student.dialogFlag'(visible) {
      if (!visible) {
        this.getTableData();
      }
    },
  },
};
